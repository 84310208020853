// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-github-app-authenticate-js": () => import("./../../../src/pages/github/app/authenticate.js" /* webpackChunkName: "component---src-pages-github-app-authenticate-js" */),
  "component---src-pages-github-app-authorize-js": () => import("./../../../src/pages/github/app/authorize.js" /* webpackChunkName: "component---src-pages-github-app-authorize-js" */),
  "component---src-pages-github-authenticate-js": () => import("./../../../src/pages/github/authenticate.js" /* webpackChunkName: "component---src-pages-github-authenticate-js" */),
  "component---src-pages-github-authorize-js": () => import("./../../../src/pages/github/authorize.js" /* webpackChunkName: "component---src-pages-github-authorize-js" */),
  "component---src-pages-github-repositories-js": () => import("./../../../src/pages/github/repositories.js" /* webpackChunkName: "component---src-pages-github-repositories-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-log-js": () => import("./../../../src/pages/log.js" /* webpackChunkName: "component---src-pages-log-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-shopify-authenticate-js": () => import("./../../../src/pages/shopify/authenticate.js" /* webpackChunkName: "component---src-pages-shopify-authenticate-js" */),
  "component---src-pages-shopify-authorize-js": () => import("./../../../src/pages/shopify/authorize.js" /* webpackChunkName: "component---src-pages-shopify-authorize-js" */),
  "component---src-pages-shopify-subscribe-js": () => import("./../../../src/pages/shopify/subscribe.js" /* webpackChunkName: "component---src-pages-shopify-subscribe-js" */)
}

